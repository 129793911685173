@import '../../assets/scss/grids.scss';

.DiscussionItem {
  border-bottom: 1px solid var(--color-5);

  .CreateComment {
    margin-left: 40px;
    margin-bottom: 16px;

    @include respond(sm) {
      margin-left: 80px;
      margin-bottom: 32px;
    }
  }

  &.hasChild {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 80px;
      bottom: 28px;
      left: 24px;
      width: 1px;
      background-color: var(--color-5);

      @include respond(sm) {
        top: 112px;
        bottom: 40px;
        left: 52px;
      }
    }
  }
}
