@import './assets/scss/grids.scss';

.App {
  max-width: 768px;
  margin: 0 auto;
  position: relative;

  &__head {
    background-color: var(--color-6);
    padding-top: 8px;
    position: sticky;
    top: 0;
    z-index: 2;

    @include respond(sm) {
      padding-top: 16px;
    }
  }
}
