@import '../../assets/scss/grids.scss';

.Comment {
  display: flex;
  align-items: flex-start;
  padding: 16px 8px;
  margin-left: 40px;

  @include respond(sm) {
    padding: 32px 20px;
    margin-left: 80px;
  }

  &.isParent {
    margin-left: 0;
  }

  &__description {
    margin-left: 8px;

    @include respond(sm) {
      margin-left: 20px;
    }

    &__name {
      font-size: 16px;

      @include respond(sm) {
        font-size: 20px;
      }
    }

    &__text {
      margin: 8px 0;
      color: var(--color-2);

      @include respond(sm) {
        margin: 16px 0;
      }
    }

    &__date {
      font-size: 14px;
      color: var(--color-2);
      padding-left: 8px;
    }
  }
}
