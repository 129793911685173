@import '../../assets/scss/grids.scss';

.CreateComment {
  padding: 0 16px 8px 12px;
  display: flex;
  align-items: center;

  @include respond(sm) {
    padding: 0 32px 16px 24px;
  }

  &__input {
    border: 1px solid var(--color-5);
    border-radius: 4px;
    width: 100%;
    padding: 0 8px;
    height: 32px;
    margin-left: 8px;

    @include respond(sm) {
      padding: 0 16px;
      height: 40px;
      margin-left: 16px;
    }
  }
}
