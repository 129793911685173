@import '../../assets/scss/grids.scss';
.Avatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 100px;
  border: 1px solid var(--color-3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @include respond(sm) {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }
}
