@import '../../assets/scss/grids.scss';

.Button {
  padding: 0 8px;
  height: 36px;
  border: none;
  background-color: transparent;
  color: var(--color-1);
  min-width: 72px;
  cursor: pointer;

  @include respond(sm) {
    padding: 0 16px;
    height: 40px;
  }

  &.themePrimary {
    background-color: var(--color-4);
    color: #fff;
  }

  &.themeLight {
    background-color: var(--color-6);
  }

  &.roundedFull {
    border-radius: 40px;
  }

  &.themeLink {
    color: var(--color-4);
    font-weight: 600;
  }
}
